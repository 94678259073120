import {
    ESTIMATION_HOUSING_UPDATE,
    ESTIMATION_HOUSING_VALIDATION_ERROR,
    ESTIMATION_HOUSING_VALIDATION_SUCCESS,
    ESTIMATION_BENEFIT_VALIDATION_SUCCESS,
    ESTIMATION_BENEFIT_VALIDATION_ERROR,
    ESTIMATION_BENEFIT_UPDATE,
    FETCH_ESTIMATION,
    FETCH_NEW_ESTIMATION,
    ESTIMATION_LOCATION_UPDATE,
    ESTIMATION_PERSONAL_DATA_UPDATE,
    ESTIMATION_NEW_REQUEST,
    GET_LOCKED_DATE,
    GET_LOCKED_DATE_RESET,
} from "../actions/typesAction";

const initState = {
    address: null,
    calculation: {
        totalDuration: 0,
        totalPrice: 0,
    },
    settings: {
        serviceId: 1,
        housingCategoryId: 1,
        housingSizeId: null,
        housingSpecificity: null,
        houseworkFrequencyId: null,
        houseworkPersonalization: {
            oven: 0,
            fridge: 0,
            bed: 0,
            vacuum: false,
            product_ecological: false,
            product_standard: false,
            cat: false,
            dog: false,
        },
        promotionCode: ""
    },
    housingSuccess: false,
    housingError: false,
    housingErrorsList: {},
    benefitSuccess: false,
    benefitError: false,
    benefitErrorsList: {},
    twiceOrMoreRequests: false,
    proId: null,
    proData: null,
    lockedDates: [],
};

const estimationReducer = (state = initState, action) => {
    switch (action.type) {
        case ESTIMATION_LOCATION_UPDATE:
            return {
                ...state,
                ...{ settings: action.payload },
            };

        case ESTIMATION_HOUSING_UPDATE:
            return {
                ...state,
                ...{ settings: action.payload },
            };

        case ESTIMATION_HOUSING_VALIDATION_SUCCESS:
            return {
                ...state,
                housingSuccess: true,
                housingError: false,
                housingErrorsList: {},
            };

        case ESTIMATION_HOUSING_VALIDATION_ERROR:
            return {
                ...state,
                housingSuccess: false,
                housingError: true,
                housingErrorsList: action.payload,
            };

        case ESTIMATION_BENEFIT_UPDATE:
            return {
                ...state,
                ...{ settings: action.payload },
            };

        case ESTIMATION_BENEFIT_VALIDATION_SUCCESS:
            return {
                ...state,
                benefitSuccess: true,
                benefitError: false,
                benefitErrorsList: {},
            };
        case ESTIMATION_BENEFIT_VALIDATION_ERROR:
            return {
                ...state,
                benefitSuccess: false,
                benefitError: true,
                benefitErrorsList: action.payload,
            };
        case ESTIMATION_PERSONAL_DATA_UPDATE:
            return {
                ...state,
                ...{ settings: action.payload },
            };
        case FETCH_ESTIMATION:
            return {
                ...state,
                ...{ calculation: action.payload },
            };
        case FETCH_NEW_ESTIMATION:
            const { estimation, categoryId, sizeId, specificity } =
                action.payload;
            return {
                ...state,
                ...{
                    calculation: estimation,
                    settings: {
                        ...state.settings,
                        housingCategoryId: categoryId,
                        housingSizeId: sizeId,
                        housingSpecificity: specificity,
                    },
                },
            };
        case ESTIMATION_NEW_REQUEST:
            return {
                ...state,
                ...{ settings: action.payload },
                // settings: {
                //     serviceId: 1,
                //     housingCategoryId: 1,
                //     housingSizeId: null,
                //     housingSpecificity: action.payload.housingSpecificity,
                //     houseworkFrequencyId: action.payload.houseworkFrequencyId,
                //     houseworkPersonalization: action.payload.houseworkPersonalization
                // },
                housingSuccess: action.payload.housingSuccess,
                twiceOrMoreRequests: action.payload.twiceOrMoreRequests,
                proId: action.payload.proId,
                proData: action.payload.proData,
            };
        case GET_LOCKED_DATE:
            return {
                ...state,
                lockedDates: [...state.lockedDates, ...action.payload],
            };
        case GET_LOCKED_DATE_RESET:
            return {
                ...state,
                lockedDates: [],
            };
        default:
            return {
                ...state,
            };
    }
};

export default estimationReducer;
